import { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import {
  FormControl,
  FormLabel,
  FormGroup,
  Checkbox,
  Typography,
  TextField,
  Button,
  FormHelperText,
  Dialog,
  DialogContent,
  DialogActions,
} from "@material-ui/core";
import { validateEmail } from "../helpers/common";
import { sendAdstEmail } from "../helpers/lambdas";
import Spinner from "../components/common/Spinner";
import { CONTACT_SUPPORT_URL } from "../constants";

const MAX_CHARS = 100;
const INNOVASEA_FISHTRACKING_URL = "https://www.innovasea.com/fish-tracking";

const useStyles = makeStyles(theme => ({
  root: {
    width: "100%",
    backgroundColor: "white",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
  },
  header: {
    height: 80,
    width: "100%",
    backgroundColor: "#f1f3f5",
    display: "flex",
    alignItems: "center",
    padding: "12.5px 20px",
  },
  headerTitle: {
    margin: theme.spacing(1),
  },
  main: {
    padding: 40,
    maxWidth: 600,
    margin: "auto",
    [theme.breakpoints.down("sm")]: {
      padding: 20,
    },
  },
  body: {
    textAlign: "justify",
  },
  form: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    maxWidth: 500,
    margin: "auto",
  },
  formSection: {
    paddingTop: 14,
    width: "100%",
  },
  checkboxLabelText: {
    flexGrow: 0,
    width: 80,
  },
  checkboxWithTextField: {
    display: "flex",
    alignItems: "center",
  },
  floatRight: {
    float: "right",
  },
  footer: {
    height: 100,
    width: "100%",
    backgroundColor: "#f1f3f5",
    display: "flex",
    alignItems: "center",
    padding: "12.5px 20px",
    justifyContent: "center",
  },
}));

const initialValues = {
  name: "",
  phonePref: false,
  phone: "",
  emailPref: false,
  email: "",
  serials: [""],
};

function ADSTPage() {
  const classes = useStyles();

  const [values, setValues] = useState(initialValues);
  const [errors, setErrors] = useState({});
  // submission state = NOT_YET | IN_PROGRESS | FAILED | SUCCEEDED
  const [submissionState, setSubmissionState] = useState("NOT_YET");
  const { name, phonePref, phone, emailPref, email, serials } = values;

  const onChange = ({ target }) => {
    setValues({ ...values, [target.name]: target.value.slice(0, MAX_CHARS) });
  };

  const onChangeCheckbox = ({ target }) => {
    setValues({ ...values, [target.name]: target.checked });
  };

  const validate = () => {
    const errors = {};
    if (!name) {
      errors.name = "Please enter your name";
    }

    if (!phonePref && !emailPref) {
      errors.contactMethod = "Please select & enter at least one method of contact.";
    }

    if (phonePref && !phone) {
      errors.phone = "Please enter your phone number (if it is a preferred method)";
    }

    if (emailPref) {
      if (!email) {
        errors.email = "Please enter your email address (if it is a preferred method)";
      } else if (!validateEmail(email)) {
        errors.email = "Please enter a valid email address (if it is a preferred method)";
      }
    }

    setErrors(errors);

    return Object.keys(errors).length === 0;
  };

  const onSubmit = () => {
    if (validate()) {
      setSubmissionState("IN_PROGRESS");

      sendAdstEmail(values)
        .then(() => setSubmissionState("SUCCEEDED"))
        .catch(() => setSubmissionState("FAILED"));
    }
  };

  return (
    <div className={classes.root}>
      <div>
        <div className={classes.header}>
          <a href={INNOVASEA_FISHTRACKING_URL}>
            <img src="/img/innovasea-default.png" height={40} className={classes.headerLogo} />
          </a>
        </div>
        <div className={classes.main}>
          <Typography variant="h5" gutterBottom>
            Lots of people dream about finding a message in a bottle, but you've done it!
          </Typography>
          <Typography paragraph className={classes.body}>
            That orange plastic tube contains a wealth of information about the behaviour of an
            animal, and there's a scientist somewhere in the world who is anxiously waiting to use
            it in their research.
          </Typography>

          <Typography paragraph className={classes.body}>
            Please use the form below to tell us about the tag that you've found and how we can get
            in touch with you to arrange to get it back to the researcher. If you'd prefer to call
            or e-mail us, you can do that by clicking <a href={CONTACT_SUPPORT_URL}>here</a>.
          </Typography>

          <form className={classes.form}>
            <div className={classes.formSection}>
              <FormControl component="fieldset" style={{ width: "100%" }}>
                <FormLabel htmlFor="name" component="legend">
                  Name
                </FormLabel>
                <WrappedTextField
                  value={name}
                  name="name"
                  onChange={onChange}
                  error={Boolean(errors.name)}
                  helperText={errors.name}
                  required
                />
              </FormControl>
            </div>
            <div className={classes.formSection}>
              <FormControl component="fieldset" style={{ width: "100%" }}>
                <FormLabel component="legend" error={Boolean(errors.contactMethod)}>
                  Preferred Method(s) of Contact
                </FormLabel>
                <FormGroup>
                  <div className={classes.checkboxWithTextField}>
                    <Checkbox checked={phonePref} name="phonePref" onChange={onChangeCheckbox} />
                    <div className={classes.checkboxLabelText}>Phone:</div>
                    <WrappedTextField
                      value={phone}
                      name="phone"
                      onChange={onChange}
                      error={Boolean(errors.phone)}
                      helperText={errors.phone}
                      disabled={!phonePref}
                      type="tel"
                    />
                  </div>
                  <div className={classes.checkboxWithTextField}>
                    <Checkbox checked={emailPref} name="emailPref" onChange={onChangeCheckbox} />
                    <div className={classes.checkboxLabelText}>Email:</div>
                    <WrappedTextField
                      value={email}
                      name="email"
                      onChange={onChange}
                      error={Boolean(errors.email)}
                      helperText={errors.email}
                      disabled={!emailPref}
                      type="email"
                    />
                  </div>
                </FormGroup>
                <FormHelperText error>{errors.contactMethod}</FormHelperText>
              </FormControl>
            </div>

            <div className={classes.formSection}>
              <FormControl component="fieldset" style={{ width: "100%" }}>
                <FormLabel component="legend">Which tag did you find?</FormLabel>
                <WrappedTextField
                  value={serials}
                  name="serials"
                  onChange={onChange}
                  placeholder="Serial No."
                />
              </FormControl>
              <FormHelperText>
                Found another tag? Wow! Enter any more serial numbers separated by commas.
              </FormHelperText>
            </div>
            <div className={classes.formSection}>
              <Button
                onClick={onSubmit}
                variant="contained"
                color="primary"
                className={classes.floatRight}
                disabled={submissionState === "IN_PROGRESS"}
              >
                Submit
              </Button>
            </div>
          </form>

          <Dialog open={submissionState === "SUCCEEDED"}>
            <DialogContent>
              <Typography>
                Submission successful.
                <br />
                Thank you for helping support ocean research! A member of our team will reach out to
                you shortly.
              </Typography>
            </DialogContent>
            <DialogActions>
              <Button variant="contained" color="primary" href={INNOVASEA_FISHTRACKING_URL}>
                OK
              </Button>
            </DialogActions>
          </Dialog>

          {submissionState === "IN_PROGRESS" ? (
            <Spinner />
          ) : submissionState === "FAILED" ? (
            <Typography color="error">
              Submission failed! Please try again or get in touch wih us{" "}
              <a href={CONTACT_SUPPORT_URL}>here</a> instead.
            </Typography>
          ) : null}
        </div>
      </div>
      <div className={classes.footer}>
        <a href={INNOVASEA_FISHTRACKING_URL}>
          <img
            src="/img/innovasea-logomark-feb2020.png"
            className={classes.logoFooter}
            height={60}
          />
        </a>
        <Typography style={{ paddingLeft: 15 }}>
          Want to learn more about Innovasea? Visit us <a href={INNOVASEA_FISHTRACKING_URL}>here</a>
          .
        </Typography>
      </div>
    </div>
  );
}

function WrappedTextField(props) {
  return <TextField margin="dense" variant="outlined" fullWidth {...props} />;
}

export default ADSTPage;
