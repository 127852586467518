import PropTypes from "prop-types";
import { useState } from "react";
import { withStyles } from "@material-ui/core/styles";
import { IconButton, Typography, Tooltip, Collapse } from "@material-ui/core";
import { Edit as IconEdit, ExpandMore as IconExpandMore } from "@material-ui/icons";
import LabeledLine from "../common/LabeledLine";
import { toTitleCase } from "../../helpers/common";
import { WindowedTable } from "../../fathom-brella";
import FlexCol from "../common/FlexCol";
import FlexRow from "../common/FlexRow";
import TransmitterDelaySequence from "./TransmitterDelaySequence";
import HRFileMessage from "../detection/HRFileMessage";

const styles = theme => ({
  detailsContainer: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
    height: "90%",
  },
  detailsTextContainer: {
    display: "flex",
    justifyContent: "center",
    paddingTop: theme.spacing(1),
  },
  detailsText: {
    width: "100%",
    display: "flex",
    flexDirection: "column",
  },
  detailsAction: {
    minWidth: theme.spacing(6), // keep spacing event when device isn't manual
  },
  delaysContainer: {
    padding: theme.spacing(1),
    paddingTop: theme.spacing(2),
  },
  idTable: {
    padding: theme.spacing(2),
    width: "100%",
    flexGrow: 1,
    display: "flex",
    flexDirection: "column",
  },
  expand: {
    marginTop: -3,
    color: theme.palette.common.darkGrey,
    cursor: "pointer",
    transition: "all 250ms ease-out",
  },
  expandCollapse: {
    transform: "rotate(180deg)",
  },
  idsExpanded: {
    paddingBottom: theme.spacing(1),
    fontWeight: "normal",
  },
});

const number2nuple = { 1: "Single", 2: "Double", 3: "Triple", 4: "Quadruple", 5: "Quintuple" };

function DeviceDetails({ device, classes, editDevice }) {
  if (!device) return null;

  let stateSuffix = "";
  if (device.state === "DEPLOYED") {
    const latestDeployEvent = device.events
      .filter(e => e.__typename === "DeviceEventDeployment")
      .sort((a, b) => b.date.localeCompare(a.date))[0];
    const dDate = new Date(latestDeployEvent.date);
    const current = new Date();
    const differenceInMs = current.getTime() - dDate.getTime();
    const differenceInDays = differenceInMs / (1000 * 3600 * 24);
    const numberOfDays = Math.round(differenceInDays);
    stateSuffix = ` (${numberOfDays} days)`;
  }

  // put the animal tags first - then by detection count
  const idCounts = device.idCounts
    ? [
        ...device.idCounts.filter(c => c.animalIds.length > 0),
        ...device.idCounts.filter(c => c.animalIds.length === 0).sort((a, b) => b.count - a.count),
      ]
    : [];

  const rxModel = device.model;

  function hrInfo(rxModel) {
    if (rxModel.indexOf("HR") > -1) {
      return <HRFileMessage />;
    }
  }
  return (
    <div className={classes.detailsContainer}>
      <div className={classes.detailsTextContainer}>
        <div className={classes.detailsText}>
          <LabeledLine label="Serial" value={device.serial} alignCenter />
          <LabeledLine label="Model" value={device.model} alignCenter />
          <LabeledLine label="StockCode" value={device.stockCode} alignCenter />
          <LabeledLine label="Sales order" value={device.salesOrder} alignCenter />
          <LabeledLine
            label="Ship date"
            value={device.shipDate && device.shipDate.split("T")[0]}
            alignCenter
          />
          <LabeledLine
            label="Source"
            value={
              device.source === "SYSPRO" ? "Fathom" : device.source === "MANUAL" ? "User" : "Mobile"
            }
            alignCenter
          />
          <LabeledLine label="State" value={device.state + stateSuffix} alignCenter titleCase />
          <LabeledList label="Capabilities" arr={device.capabilities} titleCase />
          <LabeledList
            label="Log files"
            arr={device.rxLogFiles && device.rxLogFiles.length === 0 ? ["none"] : device.rxLogFiles}
          />

          <TransmitterList device={device} classes={classes} />
          {device.sensorsSupport0ADC !== null && (
            <LabeledLine
              label="Supports Error Code"
              value={device.sensorsSupport0ADC ? "Yes" : "No"}
              alignCenter
            />
          )}
        </div>
        <div className={classes.detailsAction}>
          {device.source === "MANUAL" && (
            <IconButton onClick={e => editDevice(e)}>
              <IconEdit />
            </IconButton>
          )}
        </div>
      </div>

      {device.transmitterDelaySequence && (
        <div className={classes.delaysContainer}>
          <TransmitterDelaySequence delays={device.transmitterDelaySequence} />
        </div>
      )}
      {device.idCounts && (
        <div className={classes.idTable}>
          <Typography style={{ marginTop: 20 }}> Transmitter IDs Detected: </Typography>
          <WindowedTable
            rows={idCounts}
            sortable
            columns={[
              {
                width: 120,
                label: "Transmitter ID",
                dataKey: "displayId",
              },
              {
                width: 60,
                label: "Detections",
                dataKey: "count",
                sortFn: (a, b) => (a > b ? 1 : -1),
                numeric: true,
                renderFn: count => count.toLocaleString(),
              },
              {
                width: 40,
                label: "",
                dataKey: "animalIds",
                renderFn: animalIds =>
                  animalIds.length > 0 ? (
                    <Tooltip
                      title={`Animal${animalIds.length > 1 ? "s" : ""} associated with this ID`}
                    >
                      <img style={{ marginTop: 5 }} src="/img/animals.svg" width={25} />
                    </Tooltip>
                  ) : (
                    ""
                  ),
                flexShrink: 1,
                flexGrow: 0,
              },
            ]}
          />
        </div>
      )}
      <br />
      {rxModel && hrInfo(rxModel)}
    </div>
  );
}

function ExpandingIdList({ groupDisplayId, transmitters, classes }) {
  const [idsExpanded, setIdsExpanded] = useState(false);
  return (
    <>
      <Tooltip title={idsExpanded ? "Collapse id list" : "Expand id list"}>
        <FlexRow onClick={() => setIdsExpanded(!idsExpanded)}>
          <div>{groupDisplayId}</div>
          <IconExpandMore
            className={`${classes.expand} ${idsExpanded ? classes.expandCollapse : ""}`}
          />
        </FlexRow>
      </Tooltip>
      <Collapse in={idsExpanded} classes={{ wrapperInner: classes.idsExpanded }}>
        {transmitters.map(({ displayId }) => (
          <div key={displayId}>{displayId}</div>
        ))}
      </Collapse>
    </>
  );
}

/**
 * if there is an HTI id show non-HTI ids first
 * else if there is a sensor show non-sensor ids first
 * else sort by just id number asc
 */
function sortBySensOrId(a, b) {
  if (a.htiPulseWidth && !b.htiPulseWidth) return 1;
  if (!a.htiPulseWidth && b.htiPulseWidth) return -1;
  if (a.sensorDef && !b.sensorDef) return 1;
  if (!a.sensorDef && b.sensorDef) return -1;
  const ida = Number(a.transmitId || 0);
  const idb = Number(b.transmitId || 0);
  return ida - idb;
}

function applyIDLabels(transmitters) {
  const predationSensors = transmitters.filter(({ sensorDef }) =>
    sensorDef?.dimension?.startsWith("PREDATION")
  ).length;
  if (predationSensors) {
    // sort by FullId instead of just TagId since untriggered / triggered id is determined by full id
    transmitters.sort((a, b) => {
      if (a.displayId > b.displayId) return 1;
      if (a.displayId < b.displayId) return -1;
      return 0;
    });
  } else {
    transmitters.sort(sortBySensOrId);
  }

  let predIDSwap = 0; // determine untriggered / triggered by relative order
  transmitters.forEach(tx => {
    let idLabel = "";
    if (tx.sensorDef?.dimension == "PREDATION_IDSWAP") {
      // the second id is the triggered one:
      const trigState = predIDSwap % 2 ? "triggered" : "untriggered";
      idLabel = `Predation (${trigState}) ID`;
      predIDSwap++;
    } else {
      idLabel = `${tx.sensorDef?.displayString || "Full"} ID`;
    }
    tx.idLabel = idLabel;
  });
}

/** For devices that transmit the same id on >1 frequency, this function produces a heading
 *  to group the ids to reduce display noise.
 *  E.g. (A51-9004-8140, A52-9004-8140, A53-9004-8140 ...) get grouped under Ax-9004-8140 */
function makeGroupDisplayId(fullId) {
  if (!fullId || fullId.constructor !== String) {
    return "";
  }
  const idPos = fullId.lastIndexOf("-");
  if (idPos) {
    const transmitId = fullId.slice(idPos + 1);
    const codespace = fullId.slice(0, idPos);
    if (codespace.includes("-")) {
      const [codingClassPrefix, codingId] = codespace.split("-");
      return `${codingClassPrefix.slice(0, 1)}x-${codingId}-${transmitId}`;
    }
  }
  return fullId; // if the fullId doesn't fit this form, don't need to do anything
}

function TransmitterList({ device, classes }) {
  if (
    !(device.transmitters && device.transmitters.constructor === Array) ||
    device.transmitters.length === 0
  ) {
    return null;
  }
  applyIDLabels(device.transmitters);
  // check if ids are transmitting on a range of frequencies:
  const idMap = new Map();
  device.transmitters.forEach(({ displayId, transmitId, idLabel, sensorDef }) => {
    if (transmitId) {
      if (!idMap.has(transmitId))
        idMap.set(transmitId, {
          groupDisplayId: "",
          groupIdLabel: "",
          groupSlopeInt: {},
          transmitters: [],
        });
      idMap.get(transmitId).transmitters.push({ displayId, idLabel, sensorDef });
    }
  });
  let isMultiFreq = false;
  let isMultiFreqError = false;
  idMap.forEach(transmitId => {
    if (transmitId.transmitters.length > 1) {
      isMultiFreq = true;
      transmitId.groupDisplayId = makeGroupDisplayId(transmitId.transmitters[0].displayId);
      transmitId.groupIdLabel = transmitId.transmitters[0].idLabel;
      transmitId.groupSlopeInt = transmitId.transmitters[0]?.sensorDef || {};
      // check to make sure the group is consistent:
      transmitId.transmitters.forEach(t => {
        if (
          makeGroupDisplayId(t.displayId) != transmitId.groupDisplayId ||
          t.idLabel != transmitId.groupIdLabel
        ) {
          isMultiFreqError = true; // display normally instead then
        }
      });
    }
  });

  return (
    <FlexCol>
      <Typography align="center" variant="subtitle1" style={{ marginBottom: -2, marginTop: 10 }}>
        {device.deviceClasses.includes("RECEIVER") ? "Self-Transmitter(s)" : "Transmitter(s)"}
      </Typography>

      {isMultiFreq && !isMultiFreqError
        ? [...idMap.keys()].map(transmitId => {
            const txGroup = idMap.get(transmitId);
            return (
              <LabeledLine
                key={transmitId}
                label={txGroup.groupIdLabel}
                value={
                  <>
                    <ExpandingIdList
                      groupDisplayId={txGroup.groupDisplayId}
                      transmitters={txGroup.transmitters}
                      classes={classes}
                    />
                    {txGroup.groupSlopeInt?.slope != undefined && (
                      <div style={{ paddingBottom: 6 }}>
                        <LabeledLine
                          label="Slope"
                          value={txGroup.groupSlopeInt.slope}
                          normalFont
                          dense
                        />
                        <LabeledLine
                          label="Intercept"
                          value={txGroup.groupSlopeInt.intercept}
                          normalFont
                          dense
                        />
                      </div>
                    )}
                  </>
                }
                alignCenter
              />
            );
          })
        : device.transmitters?.map(tx => {
            if (tx.htiPulseWidth == null) {
              return (
                <LabeledLine
                  key={tx.displayId}
                  label={tx.idLabel}
                  value={
                    <>
                      <p style={{ margin: 0 }}>{tx.displayId}</p>
                      {tx.sensorDef && (
                        <div style={{ paddingBottom: 6 }}>
                          <LabeledLine label="Slope" value={tx.sensorDef.slope} normalFont dense />
                          <LabeledLine
                            label="Intercept"
                            value={tx.sensorDef.intercept}
                            normalFont
                            dense
                          />
                        </div>
                      )}
                    </>
                  }
                  alignCenter
                />
              );
            } else {
              return (
                <>
                  <Typography
                    align="center"
                    variant="subtitle2"
                    style={{ marginBottom: -6, marginTop: 8 }}
                  >
                    HTI Options
                  </Typography>
                  <div key={tx.displayId} style={{ paddingBottom: 6 }}>
                    <LabeledLine label="Period-Subcode" value={tx.displayId} alignCenter />
                    {(device.stockCode?.includes("V3D") || device.stockCode?.includes("V5D")) && (
                      <LabeledLine label="Predation Subcode" value="Yes" alignCenter />
                    )}
                    <LabeledLine label="Pulse Width" value={tx.htiPulseWidth} alignCenter />
                    <LabeledLine
                      label="Modulation"
                      value={`${number2nuple[tx.htiModulation]} Pulse`}
                      alignCenter
                    />
                  </div>
                </>
              );
            }
          })}
    </FlexCol>
  );
}

function LabeledList({ label, arr, titleCase }) {
  return (
    <LabeledLine
      label={label}
      value={arr?.map((item, i) => {
        return (
          <div key={i}>
            <p style={{ margin: 0 }}>{titleCase ? toTitleCase(item) : item}</p>
          </div>
        );
      })}
      alignCenter
    />
  );
}

LabeledList.propTypes = {
  label: PropTypes.string,
  arr: PropTypes.array,
  titleCase: PropTypes.bool,
};

DeviceDetails.propTypes = {
  device: PropTypes.object,
  editDevice: PropTypes.func,
};

export default withStyles(styles)(DeviceDetails);
