import { Component } from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import autoBind from "auto-bind/react";
import { debounce } from "lodash";
import classNames from "classnames";

import {
  RadioGroup,
  FormControlLabel,
  Radio,
  FormLabel,
  TextField,
  IconButton,
  MenuItem,
  CircularProgress,
  Checkbox,
  Button,
} from "@material-ui/core";

import { AddCircleOutline as AddCircleOutlineIcon } from "@material-ui/icons";

import { lookupSerial } from "../../redux/devices/devices-actions";

import TransmitterIdInput from "./TransmitterIdInput";
import DialogWrapper from "../common/DialogWrapper";
import FlexRow from "../common/FlexRow";

const HTI_PERIOD_MIN = 250;
const HTI_PERIOD_MAX = 35000;
const HTI_SUBCODE_MIN = 0;
const HTI_SUBCODE_MAX = 31;

const styles = theme => {
  return {
    inputsContainer: {
      padding: theme.spacing(1),
    },
    input: {
      marginTop: theme.spacing(1),
      marginBottom: theme.spacing(1),
    },
    serialWarning: {
      "& .MuiFormLabel-root": {
        color: theme.palette.warning.main,
      },
      "& .MuiFormHelperText-root": {
        color: theme.palette.warning.main,
      },
    },
    serialError: {
      "&.MuiFormHelperText-root.Mui-error": {
        color: theme.palette.error.main,
      },
    },
  };
};

class DeviceDialog extends Component {
  constructor(props) {
    super(props);
    autoBind(this);
    this.state = this.initialState();
    this.lookupDeviceDebounced = debounce(this.lookupDevice, 700).bind(this);
  }

  initialState() {
    return {
      id: "",
      serial: "",
      model: "",
      deviceClass: "RECEIVER",
      transmitterIds: [],

      serialError: null,
      serialWarning: null,
      deviceLink: null,
      deviceCopy: null,
      copyInProgress: false,
      modelError: null,

      sensorsSupport0ADC: true,

      lookupLoading: false,
      lookupResult: null,
    };
  }

  initialTransmitterState() {
    return {
      values: {
        transmissionType: "CODED_TX",
        channel: "A69",
        codingId: "",
        codespace: "",
        transmitId: "",
        isSensor: false,
        dimension: "",
        slope: "",
        intercept: "",
        htiModulation: "",
        htiPeriodMs: "",
        htiPulseWidth: "",
        htiSubcode: "",
      },
      errors: {},
    };
  }

  verifyDeviceClass() {
    const { serial, deviceClass, lookupResult } = this.state;

    if (!serial) return;

    if (!lookupResult) {
      this.setState({ serialWarning: "Serial number does not match any known devices" });
    } else if (lookupResult.deviceClasses.includes(deviceClass)) {
      this.setState({ model: lookupResult.model, serialWarning: null });
    } else {
      this.setState({ serialWarning: `Serial number matches a ${lookupResult.deviceClasses[0]}` });
    }
  }

  async lookupDevice(serial) {
    if (!serial) return;
    const { uniqueDevices, selectedStudy } = this.props;

    /* First "look up" the device locally: If the serial belongs to an existing device, don't
     * bother looking it up on the server. Otherwise, look up the expected model & deviceClass. */
    if (uniqueDevices.study.serials[serial]) {
      // Note the differences in wording to help avoid the user conflating the states.
      this.setState({ serialError: "Oops! This device already exists in this study" });
    } else if (uniqueDevices.workspace.serials[serial]) {
      this.setState({
        serialError:
          "This workspace already has a device with this serial; please link it to this study instead",
        deviceLink: selectedStudy?.id && uniqueDevices.workspace.serials[serial].id,
      });
    } else if (uniqueDevices.personal.serials[serial]) {
      this.setState({
        serialError:
          "Your personal workspace has a device with this serial; please copy it to this workspace instead",
        deviceCopy: uniqueDevices.personal.serials[serial].id,
      });
    } else {
      this.setState({ lookupLoading: true });
      const lookupResult = await lookupSerial(serial);
      this.setState({ lookupLoading: false, lookupResult }, this.verifyDeviceClass);
    }
  }

  addtransmitterId() {
    const transmitterIds = [...this.state.transmitterIds, this.initialTransmitterState()];
    this.setState({ transmitterIds });
  }

  removetransmitterId(i) {
    const transmitterIds = [...this.state.transmitterIds];
    transmitterIds.splice(i, 1);
    this.setState({ transmitterIds });
  }

  // Checking for any blocking errors, update state. Return true if valid, false otherwise
  validate() {
    const requiredError = "Required field";
    const { serial, deviceClass, model } = this.state;

    const rxWithNoSerial = deviceClass === "RECEIVER" && !serial;
    const serialError = rxWithNoSerial ? requiredError : this.state.serialError;

    const modelError = !model ? requiredError : null;

    let txErrors = false;
    const requiredFieldsMap = {
      isSensor: ["dimension", "slope", "intercept"],
      CODED_TX: ["codespace", "transmitId"],
      ACOUSTIC_HTI: ["htiPeriodMs", "htiSubcode", "htiPulseWidth", "htiModulation"],
    };

    const transmitterIds = this.state.transmitterIds.map(({ values }) => {
      const errors = {};
      const requiredFields = [
        ...((values.transmissionType == "CODED_TX" && requiredFieldsMap["CODED_TX"]) || []),
        ...((values.transmissionType == "ACOUSTIC_HTI" && requiredFieldsMap["ACOUSTIC_HTI"]) || []),
        ...((values.isSensor &&
          values.dimension.indexOf("PREDATION") < 0 &&
          requiredFieldsMap["isSensor"]) ||
          []),
      ];

      requiredFields.forEach(field => {
        if (!values[field] && values[field] !== 0) {
          errors[field] = requiredError;
          txErrors = true;
        }
      });

      if (values.isSensor) {
        errors.slope = isNaN(Number(values.slope)) ? "Slope must be a number" : undefined;
        errors.intercept = isNaN(Number(values.intercept))
          ? "Intercept must be a number"
          : undefined;
        txErrors = txErrors || errors.slope || errors.intercept;
      }

      if (values.transmissionType == "ACOUSTIC_HTI") {
        const htiSubcode = Number(values.htiSubcode);
        const htiPeriodMs = Number(values.htiPeriodMs);
        errors.htiPeriodMs =
          isNaN(htiPeriodMs) || htiPeriodMs < HTI_PERIOD_MIN || htiPeriodMs > HTI_PERIOD_MAX
            ? `Period must be a number between ${HTI_PERIOD_MIN} and ${HTI_PERIOD_MAX}`
            : undefined;
        errors.htiSubcode =
          isNaN(htiSubcode) ||
          htiSubcode < HTI_SUBCODE_MIN ||
          htiSubcode > HTI_SUBCODE_MAX ||
          Math.trunc(htiSubcode) != htiSubcode
            ? `Subcode must be an integer between ${HTI_SUBCODE_MIN} and ${HTI_SUBCODE_MAX}`
            : undefined;
        txErrors = txErrors || errors.htiPeriodMs || errors.htiSubcode;
      }

      return { values, errors };
    });
    this.setState({ serialError, modelError, transmitterIds });

    return !(serialError || modelError || txErrors);
  }

  handleSubmit() {
    if (!this.validate()) return;

    const { id, serial, model, deviceClass, sensorsSupport0ADC, transmitterIds } = this.state;
    const { mode, handleDeviceSubmit } = this.props;
    const transmitters = transmitterIds.map(tx => tx.values);
    const device = { id, serial, model, deviceClass, transmitters, sensorsSupport0ADC };
    handleDeviceSubmit(mode, device);
  }

  reset() {
    const { mode, device } = this.props;
    const state = this.initialState();

    if (mode === "edit") {
      // populate state with initial values from props
      state.id = device.id;
      state.serial = device.serial;
      state.model = device.model;
      state.deviceClass = device.deviceClasses[0];
      state.sensorsSupport0ADC = device.sensorsSupport0ADC;

      device.transmitters.forEach(tx => {
        const txState = this.initialTransmitterState();
        const channel = tx.codespaceDisplayString.split("-")[0];
        const codingId = tx.codingId;
        const codespace = tx.codespaceDisplayString;
        const transmitId = tx.transmitId;

        txState.values.channel = channel;
        txState.values.codingId = codingId;
        txState.values.codespace = codespace;
        txState.values.transmitId = transmitId;

        if (tx.transmissionType == "ACOUSTIC_HTI") {
          txState.values.transmissionType = tx.transmissionType;
          txState.values.htiModulation = tx.htiModulation;
          txState.values.htiPeriodMs = tx.htiPeriodMs;
          txState.values.htiPulseWidth = tx.htiPulseWidth;
          txState.values.htiSubcode = tx.htiSubcode;
        }

        if (tx.sensorDef) {
          txState.values.isSensor = true;
          txState.values.dimension = tx.sensorDef.dimension;
          txState.values.slope = tx.sensorDef.slope;
          txState.values.intercept = tx.sensorDef.intercept;
        }

        state.transmitterIds.push(txState);
      });
    }

    this.setState(state);
  }

  handleDeviceClass(_event, deviceClass) {
    this.setState({ model: "", deviceClass }, this.verifyDeviceClass);
  }

  handleSerial(event) {
    const serial = event.target.value;
    this.lookupDeviceDebounced(serial);
    this.setState({
      serial,
      serialError: false,
      serialWarning: false,
      deviceLink: null,
      deviceCopy: null,
    });
  }

  handleTransmitterChange(i, field, value) {
    const transmitterIds = [...this.state.transmitterIds];
    const txId = transmitterIds[i];

    const values = { ...txId.values, [field]: value };
    const errors = { ...txId.errors, [field]: null };

    if (field === "codespace") {
      if (!this.codespaceExists(value)) {
        values.channel = "";
        values.codingId = "";
      }
    }

    transmitterIds[i] = { values, errors };
    this.setState({ transmitterIds });
  }

  codespaceExists(codespace) {
    return this.props.codespaces.some(cs => cs.codespaceDisplayString === codespace);
  }

  setCopyInProgress(isInProgress) {
    this.setState({ copyInProgress: isInProgress });
  }

  render() {
    const {
      serial,
      model,
      deviceClass,
      transmitterIds,
      serialError,
      serialWarning,
      modelError,
      lookupLoading,
      sensorsSupport0ADC,
      deviceLink,
      deviceCopy,
      copyInProgress,
    } = this.state;
    const {
      toggle,
      mode,
      classes,
      codespaces,
      models,
      selectedStudy,
      copyDevicesToWorkspace,
      linkDevicesToStudy,
    } = this.props;

    const isReceiver = deviceClass === "RECEIVER";
    const modelOptions = isReceiver ? models.receiverModels : models.tagModels;
    const transmitterLabel = isReceiver ? "Self-Transmitter ID" : "Transmitter ID";
    const isSensorTag = transmitterIds.some(tx => tx.values.isSensor);

    return (
      <DialogWrapper
        open={mode === "add" || mode === "edit"}
        title={mode === "add" ? "Add Manual Device" : "Edit Manual Device"}
        okAction={this.handleSubmit}
        cancelAction={toggle}
        onEnter={() => this.reset()}
        buttons={({ cancelAction, okAction }) => (
          <FlexRow itemSpacing={1}>
            <Button onClick={cancelAction} variant="outlined">
              Cancel
            </Button>
            {!deviceLink && !deviceCopy && (
              <Button
                onClick={okAction}
                color="primary"
                variant="contained"
                disabled={serialError || modelError}
              >
                {mode === "add" ? "Add Device" : "Update Device"}
              </Button>
            )}
            {deviceLink && (
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={() => {
                  linkDevicesToStudy({ studyId: selectedStudy?.id, deviceIds: [deviceLink] });
                  cancelAction();
                }}
              >
                link to this study
              </Button>
            )}
            {deviceCopy && (
              <Button
                variant="contained"
                color="primary"
                size="small"
                onClick={async () => {
                  this.setCopyInProgress(true);
                  await copyDevicesToWorkspace([deviceCopy]);
                  this.setCopyInProgress(false);
                  toggle();
                }}
              >
                {copyInProgress ? (
                  <CircularProgress color="secondary" size={20} />
                ) : (
                  "copy to this workspace"
                )}
              </Button>
            )}
          </FlexRow>
        )}
        maxWidth="xs"
      >
        <RadioGroup value={deviceClass} onChange={this.handleDeviceClass} row>
          <FormControlLabel value="RECEIVER" control={<Radio />} label="Receiver" />
          <FormControlLabel value="TAG" control={<Radio />} label="Tag" />
        </RadioGroup>

        <div className={classes.inputsContainer}>
          <TextField
            size="small"
            fullWidth
            required={isReceiver}
            label="Serial Number"
            className={classNames(
              serialError ? classes.serialError : serialWarning ? classes.serialWarning : null,
              classes.input
            )}
            error={Boolean(serialError)}
            helperText={serialError || serialWarning}
            value={serial}
            onChange={this.handleSerial}
            disabled={mode === "edit"}
            title={
              (mode === "edit" &&
                "Cannot edit the serial number once created. Please create a new one instead.") ||
              undefined
            }
          />

          {lookupLoading && <CircularProgress style={{ position: "absolute" }} size={20} />}

          <TextField
            size="small"
            required
            select
            fullWidth
            value={model}
            label="Model"
            className={classes.input}
            onChange={event => this.setState({ model: event.target.value, modelError: false })}
            error={Boolean(modelError)}
            helperText={modelError}
          >
            {modelOptions?.sort().map(model => (
              <MenuItem key={model} value={model}>
                {model}
              </MenuItem>
            ))}
          </TextField>

          <FormLabel>{transmitterLabel + "(s)"}</FormLabel>
          <IconButton
            edge="end"
            color="primary"
            title={`Add ${transmitterLabel}`}
            onClick={this.addtransmitterId}
          >
            <AddCircleOutlineIcon />
          </IconButton>

          {transmitterIds.map((tx, i) => (
            <TransmitterIdInput
              codespaces={codespaces}
              deviceClass={deviceClass}
              values={tx.values}
              setValue={(field, value) => this.handleTransmitterChange(i, field, value)}
              errors={tx.errors}
              handleRemove={() => this.removetransmitterId(i)}
              transmitterLabel={transmitterLabel}
              key={i}
              model={model}
            />
          ))}

          {isSensorTag && (
            <div style={{ marginTop: 10, marginBottom: 10 }}>
              <Checkbox
                color="primary"
                checked={sensorsSupport0ADC}
                onChange={event => this.setState({ sensorsSupport0ADC: event.target.checked })}
              />
              <span style={{ color: "gray" }}>Supports Error Code</span>
            </div>
          )}
        </div>
      </DialogWrapper>
    );
  }
}

DeviceDialog.propTypes = {
  mode: PropTypes.string,
  toggle: PropTypes.func,
  codespaces: PropTypes.array,
  models: PropTypes.object,
  handleDeviceSubmit: PropTypes.func,
  device: PropTypes.object,
  uniqueDevices: PropTypes.object,
  selectedStudy: PropTypes.object,
  copyDevicesToWorkspace: PropTypes.func,
};

export default withStyles(styles)(DeviceDialog);
