import { Thunk } from "../common";
import {
  DetectionAction,
  DetectionRow,
  DetectionFilterInput,
  DetectionState,
  DetectionCountOptions,
  DetectionCountHistogram,
  DetectionCountAbacus,
  DetectionCountAPIResAbacus,
} from "./detection-types";
import { RxList } from "../../components/detection/detection-types";
import { snackbarError } from "../snackbar/snackbar-actions";
import { callGqlApi } from "../../helpers/api";
import { Transmitter } from "../../helpers/glossary";
import gql from "../gqlTag";
import * as d3 from "d3";
import { handleGqlErrors } from "../gql-error/gql-error-actions";
import { createDownloadFromBlob } from "../files/files-actions";
import { isEqual } from "lodash";

export function queryDetectionSummary(): Thunk<void, DetectionAction> {
  return async dispatch => {
    dispatch({ type: "DETECTION_SUMMARY_START_LOADING_DATA" });
    const summaryQuery = gql`
      query {
        detectionSummary {
          totalDetections
          uniqueIds
          uniqueReceivers
          idSummary {
            idCount {
              displayId
              count
              animalIds
            }
            receivers {
              serial
              deviceId
            }
          }
          receiverSummary {
            count
            receiver {
              serial
              deviceId
            }
            idCounts {
              displayId
              count
              animalIds
            }
          }
        }
      }
    `;
    callGqlApi(summaryQuery)
      .then(res => dispatch({ type: "DETECTION_SUMMARY_SET", payload: res.detectionSummary }))
      .catch(errors => {
        console.error(errors);
        dispatch({ type: "DETECTION_SUMMARY_ERROR" });
        dispatch(
          snackbarError(`There was an error fetching detection data. Please try again later`) as any
        );
      });
  };
}

export function resetTable(): Thunk<void, DetectionAction> {
  return dispatch => {
    dispatch({
      type: "DETECTION_RESET_TABLE",
    });
  };
}

export function queryDetections(
  filters: DetectionFilterInput,
  rxs: RxList
): Thunk<void, DetectionAction> {
  return async (dispatch, getState) => {
    let resDet;
    let currentRows: DetectionRow[] = [];
    let start = 0;
    const fileList = getState().files.fileList;
    const rxSerials = rxs.map(rx => rx.serial);
    const filesWithRelevantRxs = fileList.filter(
      f => f.rxLogProperties && rxSerials?.includes(f.rxLogProperties.serial)
    );

    // Get the files to populate file name column
    const relevantFiles =
      filters?.includeReceiverSerials?.length === 0
        ? filesWithRelevantRxs
        : filesWithRelevantRxs.filter(
            f =>
              f.rxLogProperties &&
              filters.includeReceiverSerials?.includes(f.rxLogProperties.serial)
          );
    // Fetch loop
    dispatch({ type: "DETECTION_TABLE_START_LOADING" });
    try {
      do {
        resDet = await callGqlApi(
          gql`
            query allDet($start: Int!, $pageSize: Int!, $filters: DetectionFilterInput) {
              allDetections(start: $start, pageSize: $pageSize, filters: $filters) {
                data
                nextPageStart
              }
            }
          `,
          { start, pageSize: 100000, filters }
        );

        // Stop loop if this query has been cancelled
        if (!isEqual(getState().detection.currentFilters, filters)) {
          break;
        }

        // parse data and link file ids
        const data = d3.csvParse(resDet.allDetections.data) as any[];
        currentRows = [
          ...currentRows,
          ...data.map(r => ({
            ...r,
            id: `${r.time}-${r.full_id}`,
            sensor_value:
              r.sensor_value?.constructor === Number ? parseFloat(r.sensor_value) : r.sensor_value,
            fileNames: relevantFiles
              .filter(f => r.files.includes(f.id))
              .map(f => f.name)
              .join(", "),
          })),
        ];

        dispatch({
          type: "DETECTION_SET_TABLE",
          payload: {
            rows: currentRows,
            state: "LOADING",
          },
        });

        start = resDet.allDetections.nextPageStart; // start will be null once all detections are fetched
      } while (start);
      dispatch({
        type: "DETECTION_TABLE_COMPLETE",
      });
    } catch (e) {
      dispatch({
        type: "DETECTION_TABLE_ERROR",
      });
      dispatch(handleGqlErrors(e));
    }
  };
}

export function cancelCurrentQuery(): Thunk<void, DetectionAction> {
  return (dispatch, getState) => {
    if (getState().detection.table.status === "LOADING") {
      dispatch({
        type: "DETECTION_TABLE_CANCEL",
      });
    }
  };
}

export function downloadDetectionsCSV() {
  return (_, getState) => {
    const detState: DetectionState = getState().detection;
    const tableState = detState.table;

    let csvStr = `Date Time,${Transmitter.title},Receiver Serial,Sensor Value, Sensor,Files(s)\n`;
    tableState.rows.forEach(r => {
      csvStr += `${r.time},${r.full_id},${r.serial},${
        r.sensor_value === null ? "" : r.sensor_value
      },${r.sensor_type},${r.fileNames.replaceAll(",", ";")}\n`;
    });

    const blob = new Blob([csvStr], { type: "text/csv" });
    createDownloadFromBlob(blob, "detections.csv");
  };
}

/** Queries the detectionCountsPerInterval gql endpoint and returns the result. Errors are
 * intentionally not caught so that the calling function can handle it with dispatch.
 *
 * The result is left in its "pure" state because the caller may wish to parse results differently
 */
async function fetchDetectionCountsPerIntervalGql(
  filters: DetectionFilterInput,
  options: DetectionCountOptions
) {
  const detCountsResult: {
    detectionCountsPerInterval: {
      periodMs: number[];
      count: number[];
      serial?: string[];
      fullId?: string[];
    };
  } = await callGqlApi(
    gql`
      query fetchDetectionCountsPerInterval(
        $filters: DetectionFilterInput!
        $options: DetectionCountOptions!
      ) {
        detectionCountsPerInterval(filters: $filters, options: $options) {
          periodMs
          count
          serial
          fullId
        }
      }
    `,
    { filters, options }
  );
  return detCountsResult.detectionCountsPerInterval;
}

export function fetchDetectionCountsHistogram(
  filters: DetectionFilterInput,
  binIntervalSeconds = 3600
): Thunk<void, DetectionAction> {
  // this may move into state if we decide on dynamic bin size:
  const histogramDataOptions: DetectionCountOptions = {
    binIntervalSeconds,
    byReceiverSerial: false,
    byTransmitterIDs: false,
  };
  return async (dispatch, getState) => {
    if (getState().detection.histogram.status === "UNLOADED") {
      dispatch({ type: "DETECTION_SET_HISTOGRAM_INIT_LOADING", payload: { filters } });
    } else {
      dispatch({ type: "DETECTION_SET_HISTOGRAM_LOADING", payload: { filters } });
    }
    const detCountsHistogram: DetectionCountHistogram[] = [];

    let detCountsFetched: {
      periodMs: number[];
      count: number[];
    };

    try {
      detCountsFetched = await fetchDetectionCountsPerIntervalGql(filters, histogramDataOptions);
    } catch (ex) {
      dispatch(handleGqlErrors(ex));
      return;
    }

    try {
      if (detCountsFetched?.periodMs?.length && detCountsFetched?.count?.length) {
        detCountsFetched.periodMs.forEach((dt, idx) => {
          detCountsHistogram.push({
            dt,
            count: detCountsFetched.count[idx],
          });
        });
      }

      dispatch({
        type: "DETECTION_SET_HISTOGRAM",
        payload: { detCountsHistogram, filters },
      });
    } catch (ex) {
      console.error("fetchDetectionCountsHistogram failed to process data", ex);
    }
  };
}

export function queryAbacus(
  filters: DetectionFilterInput,
  abacusOptions: DetectionCountOptions
): Thunk<void, DetectionAction> {
  return async (dispatch, getState) => {
    if (getState().detection.abacus.status === "UNLOADED") {
      dispatch({ type: "DETECTION_SET_ABACUS_INIT_LOADING", payload: { filters } });
    } else {
      dispatch({ type: "DETECTION_SET_ABACUS_LOADING", payload: { filters } });
    }

    try {
      const detCountsFetched = (await fetchDetectionCountsPerIntervalGql(
        filters,
        abacusOptions
      )) as DetectionCountAPIResAbacus;

      const countPerGroup: DetectionCountAbacus[] = [];

      if (detCountsFetched?.periodMs?.length && detCountsFetched?.count?.length) {
        detCountsFetched.periodMs.forEach((dt, idx) =>
          countPerGroup.push({
            dt,
            count: detCountsFetched.count[idx],
            rx: detCountsFetched.serial[idx],
            tx: detCountsFetched.fullId[idx],
          })
        );
      }
      dispatch({ type: "DETECTION_SET_ABACUS_DATA", payload: { countPerGroup, filters } });
    } catch (ex) {
      dispatch(handleGqlErrors(ex));
      return;
    }
  };
}

export function clearDetectionData(): Thunk<void, DetectionAction> {
  return dispatch => {
    dispatch({
      type: "DETECTION_CLEAR_DATA",
    });
  };
}

export function getDetCountApi(filters: DetectionFilterInput): Thunk<void, DetectionAction> {
  return (dispatch, getState) => {
    dispatch({ type: "DETECTION_COUNT_LOADING", payload: { filters } });
    const query = gql`
      query allDetCount($filters: DetectionFilterInput) {
        allDetectionCount(filters: $filters)
      }
    `;
    callGqlApi(query, {
      filters,
    })
      .then(data => {
        const lastFilters = getState().detection.count.filters;
        if (isEqual(lastFilters, filters)) {
          dispatch({ type: "DETECTION_SET_COUNT", payload: { count: data.allDetectionCount } });
        }
      })
      .catch(e => console.error("GQL ERROR: ", e));
  };
}
