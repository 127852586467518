import { Typography } from "@material-ui/core";
import FlexCol from "../common/FlexCol";

function HRFileMessage() {
  return (
    <FlexCol itemSpacing={2} marginLevel={2}>
      <Typography gutterBottom variant="caption">
        Support for working with detection data from HR2 and HR3 receivers is under development.
      </Typography>

      <Typography variant="caption">
        The Devices, Animals and Deployments metadata tools support all Innovasea receivers and
        tags, including those in HR2 and HR3 studies.
      </Typography>
    </FlexCol>
  );
}

export default HRFileMessage;
