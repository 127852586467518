import { Typography, Button, LinearProgress, Link } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import DialogWrapper from "../DialogWrapper";
import { WindowedTable } from "../../../fathom-brella";
import { MissingDevice } from "./types";
import { CONTACT_SUPPORT_URL } from "../../../constants";

type Props = {
  open: boolean;
  missingDevices: MissingDevice[];
  isPersonalWorkspace: boolean;
  billingAccountLinked: boolean;
  onClose: () => void;
  onRetry: () => void;
  progress: number | undefined;
};

// these should match the WindowedTable default, but defined here incase the default changes
const TABLE_HEADER_HEIGHT = 40;
const TABLE_ROW_HEIGHT = 32;

const useStyles = makeStyles(theme => ({
  scrollContainer: {
    maxHeight: 300,
    overflowY: "auto",
  },
  buttonContainer: {
    width: "100%",
    display: "flex",
    justifyContent: "space-between",
  },
  loadingContainer: {
    height: 100,
    display: "flex",
    flexDirection: "column",
    width: "100%",
    justifyContent: "center",
  },
  tableContainer: {
    marginBottom: theme.spacing(2),
  },
}));

function MissingImportDevicesDialog({
  open,
  missingDevices,
  isPersonalWorkspace,
  billingAccountLinked,
  onClose,
  onRetry,
  progress,
}: Props) {
  const classes = useStyles();
  const loading = progress !== undefined;

  return (
    <DialogWrapper
      open={open}
      title="Missing Devices"
      cancelAction={onClose}
      okAction={onRetry}
      buttons={({ cancelAction, okAction }) => (
        <div className={classes.buttonContainer}>
          <Button onClick={okAction} variant="text" disabled={loading}>
            Add manual devices {"&"} retry
          </Button>
          <Button onClick={cancelAction} variant="outlined" disabled={loading}>
            Cancel
          </Button>
        </div>
      )}
    >
      {loading ? (
        <div className={classes.loadingContainer}>
          <Typography>Creating Devices</Typography>
          <div style={{ width: "100%" }}>
            <LinearProgress variant="determinate" value={progress} />
          </div>
        </div>
      ) : (
        <>
          <Typography>
            The following devices were referenced in the uploaded spec sheet but were not found in
            this workspace{isPersonalWorkspace ? "" : <> nor in your personal workspace</>}:
          </Typography>

          <div
            className={classes.tableContainer}
            style={{
              height: Math.min(200, TABLE_HEADER_HEIGHT + TABLE_ROW_HEIGHT * missingDevices.length),
            }}
          >
            <WindowedTable
              rowHeight={TABLE_ROW_HEIGHT}
              rows={missingDevices}
              rowIdKey="serial"
              columns={[
                { width: 70, label: "Serial", dataKey: "serial" },
                { width: 70, label: "Model", dataKey: "model" },
                { width: 70, label: "Transmitter ID", dataKey: "txDisplayIds" },
              ]}
            />
          </div>

          {billingAccountLinked ? (
            <Typography paragraph>
              This means the devices are not linked to your Innovasea billing account. In this case,
              it would be best to have a collaborator with access to the devices add them to a
              shared workspace. You will then have full access to those devices as a member of that
              workspace.
            </Typography>
          ) : (
            <>
              <Typography paragraph>
                In this case, it would be best to gain access to the devices through the Innovasea
                database. If you have an Innovasea billing account that includes the orders of these
                devices, you can gain access to all of those devices by linking your billing account
                to your Fathom Central account. To do so,{" "}
                <Link href={CONTACT_SUPPORT_URL}>contact support</Link>.
              </Typography>
              <Typography paragraph>
                Otherwise, you can have a collaborator with access to the devices add them to a
                shared workspace. You will then have full access to those devices as a member of
                that workspace.
              </Typography>
            </>
          )}

          <Typography paragraph>
            Alternatively, you may choose to create manual device records for the missing devices.
            Manual devices are not validated against the Innovasea database and only include a
            minimal set of data.
          </Typography>
        </>
      )}
    </DialogWrapper>
  );
}

export default MissingImportDevicesDialog;
