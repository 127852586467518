import { Typography } from "@material-ui/core";
import FlexCol from "../common/FlexCol";

function Gen2FileMessage() {
  return (
    <FlexCol itemSpacing={2} marginLevel={2}>
      <Typography gutterBottom variant="caption">
        Support for working with detection data from Generation 2 receivers is under development.
      </Typography>

      <Typography variant="caption">
        The Devices, Animals and Deployments metadata tools support all Innovasea receivers and
        tags.
      </Typography>
    </FlexCol>
  );
}

export default Gen2FileMessage;
