import { Component } from "react";
import PropTypes from "prop-types";
import autoBind from "auto-bind/react";
import { compose } from "redux";
import { connect } from "react-redux";
import {
  Radio,
  RadioGroup,
  FormControlLabel,
  Typography,
  ExpansionPanel,
  ExpansionPanelSummary,
  ExpansionPanelDetails,
  Popover,
  MenuList,
  MenuItem,
  Paper,
  Checkbox,
  TextField,
  CircularProgress,
  Tooltip,
  IconButton,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import {
  ViewColumn as ViewColumnIcon,
  Check,
  Add as IconAdd,
  Delete as IconDelete,
  BatteryStd as IconBattery,
  PowerOff as IconPowerOff,
  FlashOn as IconFlashOn,
  ExpandMore as IconExpandMore,
  Smartphone as MobileAddIcon,
  Person as ManualDeviceIcon,
  Link as IconLink,
  LinkOff as IconLinkOff,
  Input as IconCopyToWorkspace,
} from "@material-ui/icons";

import { WindowedTable, SpinnerInnovasea, TableSearchBar } from "../fathom-brella";

import {
  readDeviceList,
  addDeviceManually,
  editDeviceManually,
  deleteDevices,
  rebatteryDevices,
  retireDevices,
  unretireDevices,
  deleteDeviceEvents,
  copyDevicesToWorkspace,
  getPersonalDevices,
  getCodespaces,
  addDevicesBySerial,
} from "../redux/devices/devices-actions";

import { snackbarError } from "../redux/snackbar/snackbar-actions";
import { removeFromStudy, addToStudy } from "../redux/study/study-actions";

import { toTitleCase } from "../helpers/common";
import { studyVisibleObjects } from "../helpers/study";

import IconMenuHoriz from "../components/common/IconMenuHoriz";
import WarningIcon from "../components/common/WarningIcon";
import DialogWrapper from "../components/common/DialogWrapper";
import DeviceDialog from "../components/devices/DeviceDialog";
import RebatteryDialog from "../components/devices/RebatteryDialog";
import DeviceDetailsPanel from "../components/devices/DeviceDetailsPanel";
import DevicesAddDialog from "../components/devices/DevicesAddDialog";
import StudyEmptyMessage from "../components/study/StudyEmptyMessage";
import MenuBar from "../components/common/MenuBar";
import FlexCol from "../components/common/FlexCol";

import StudyChooseDialog from "./StudyChooseDialog";
import StudyUnlinkDialog from "./StudyUnlinkDialog";
import StudyLinkDialog from "./StudyLinkDialog";
import StudyLinkChips from "../components/study/StudyLinkChips";
import { queryDetectionSummary } from "../redux/detections/detection-actions";
import ResizableSplitPanel from "../components/common/ResizableSplitPanel";
import DeleteDialogButtons from "../components/common/DeleteDialogButtons";
import DeleteWarning from "../components/common/DeleteWarning";
import DevicesAddSerial from "../components/devices/DevicesAddSerial";

import { studyNames } from "../helpers/study";

export const deviceCopyMessage = (
  <Typography variant="body2" color="textSecondary">
    Data about copied devices remains unchanged in the personal workspace, and any data added about
    the device in a workspace does not affect any other workspace.
  </Typography>
);

const styles = () => ({
  main: {
    display: "flex",
    flexDirection: "column",
    height: "100%",
  },
  panel: {
    margin: "0px !important",
    boxShadow: "none",
    "&:before": {
      display: "none",
    },
  },
  deviceTableContainer: {
    flexGrow: 1,
    height: "100%",
  },
  loading: {
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
});

class Devices extends Component {
  constructor(props) {
    super(props);
    autoBind(this);

    this.state = {
      selection: [],
      showRetired: false,
      activeTab: "all",
      mode: "closed",
      searchText: "",
      columnAnchorEl: null,
      selectedColKeys: [
        "serial",
        "model",
        "transmitters",
        "state",
        "inStudies",
        "rxLogFiles",
        "detectCount",
      ],
      selectingTargetWorkspace: false, // is the dialog for selecting a target shared workspace open?
      targetWorkspaceId: "", // the selected target shared workspace
      devicesAddSelection: [],
      devicesAddOpen: false,
      studyChooseOpen: false,
      studyLinkOpen: false,
      studyUnlinkOpen: false,
    };
  }

  componentDidMount() {
    this.fetchData();
    this.setInitialTargetWorkspace();
  }

  componentDidUpdate(prevProps) {
    if (this.props.workspaces !== prevProps.workspaces) {
      this.setInitialTargetWorkspace();
    }
    if (
      this.props.selectedWorkspace !== prevProps.selectedWorkspace ||
      this.props.selectedStudy !== prevProps.selectedStudy
    ) {
      this.clearSelection();
    }
    if (this.props.selectedWorkspace !== prevProps.selectedWorkspace) {
      this.fetchData();
    }
  }

  fetchData() {
    this.props.dispatch(readDeviceList());
    this.props.dispatch(queryDetectionSummary());
    this.props.dispatch(getCodespaces());
  }

  // set initial value of targetWorkspaceId to first shared workspace
  setInitialTargetWorkspace() {
    const { workspaces } = this.props;
    const firstSharedWorkspace = workspaces && workspaces.find(w => !w.isPersonal);
    this.setState({ targetWorkspaceId: (firstSharedWorkspace && firstSharedWorkspace.id) || "" });
  }

  openAddManualDeviceDialog(event) {
    event.preventDefault();
    this.setState({
      devicesAddOpen: false,
      mode: "add",
    });
  }

  openDevicesAddSerialDialog(event) {
    event.preventDefault();
    this.setState({
      devicesAddOpen: false,
      devicesAddSerialOpen: true,
    });
  }

  openEditDeviceDialog(event) {
    event.preventDefault();
    this.setState({
      mode: "edit",
    });
  }

  columnSelectionHandler(dataKey) {
    const currentCols = [...this.state.selectedColKeys];
    if (currentCols.includes(dataKey)) {
      this.setState({ selectedColKeys: currentCols.filter(c => c !== dataKey) });
    } else {
      currentCols.push(dataKey);
      this.setState({ selectedColKeys: currentCols });
    }
  }

  openDeleteDeviceDialog(event) {
    event.preventDefault();
    this.setState({
      mode: "delete",
    });
  }

  closeDeviceDialog() {
    this.setState({
      mode: "closed",
    });
  }

  openRebatteryDialog() {
    this.setState({
      mode: "rebattery",
    });
  }

  async handleDeviceSubmit(type, device) {
    const { selectedStudy, dispatch } = this.props;
    this.closeDeviceDialog();
    const deviceInput = formatDevice(device);
    if (type === "add") {
      await dispatch(addDeviceManually(deviceInput, selectedStudy?.id));
    } else {
      await dispatch(editDeviceManually(deviceInput));
    }
    dispatch(readDeviceList());
  }

  handleDeviceRebattery(event, containsErrors) {
    if (containsErrors) {
      this.props.dispatch(snackbarError("Please fill out all required device information"));
      return Promise.reject("Fields missing.");
    }
    this.closeDeviceDialog();
    this.props.dispatch(rebatteryDevices(event));
  }

  handleDeviceDelete(devices) {
    this.closeDeviceDialog();
    return this.props.dispatch(deleteDevices(devices));
  }

  actionUnlink() {
    this.props.dispatch(
      removeFromStudy(
        { studyId: this.props.selectedStudy.id, deviceIds: this.state.selection },
        true
      )
    );
    this.closeDeviceDialog();
  }

  clearSelection() {
    this.setState({ selection: [] });
  }

  retireDevices(event, deviceIds) {
    event.preventDefault();
    return this.props.dispatch(retireDevices(deviceIds, this.clearSelection));
  }

  unretireDevices(event, deviceIds) {
    event.preventDefault();
    return this.props.dispatch(unretireDevices(deviceIds));
  }

  visibleDeviceList() {
    const { devices } = this.props;
    const { activeTab, showRetired } = this.state;

    return devices.filter(
      device =>
        (activeTab === "all" ||
          (activeTab === "receivers" && device.deviceClasses.includes("RECEIVER")) ||
          (activeTab === "tags" && device.deviceClasses.includes("TAG"))) &&
        (showRetired || device.retired === false)
    );
  }

  getConflictMessage(conflicts) {
    const { selectedStudy } = this.props;
    const messageLookup = conflict => {
      if (conflict.__typename === "DeviceConflictSerial") {
        return `multiple devices with serial (${conflict.serial})`;
      } else if (conflict.__typename === "DeviceConflictDisplayId") {
        return `multiple devices with transmit ID (${conflict.displayId})`;
      }
      return "";
    };
    let message = "";

    if (selectedStudy) {
      conflicts = conflicts.filter(c => c.studyId === selectedStudy.id).map(c => c.conflict);
    }

    conflicts.forEach((c, i) => {
      message += messageLookup(c);
      if (i !== conflicts.length - 1) {
        message += ", ";
      }
    });
    return message;
  }

  openAddDevicesDialog() {
    this.getPersonalDevices();
    this.setState({ devicesAddOpen: true });
  }

  closeDevicesAddDialog() {
    this.setState({ devicesAddOpen: false, devicesAddSelection: [] });
  }

  /** Dispatch the action to get the list of devices from the personal workspace.
   * Do not do this if this is currently the personal workspace or if the data
   * has already been loaded (since it changes very infrequently)
   */
  getPersonalDevices() {
    if (this.props.selectedWorkspace.isPersonal || this.props.personalDevices.length > 0) {
      return;
    }
    this.props.dispatch(getPersonalDevices());
  }

  setDevicesAddSelection(devicesAddSelection) {
    this.setState({ devicesAddSelection });
  }

  async copyDevicesToWorkspace() {
    await this.props.dispatch(
      copyDevicesToWorkspace(this.state.devicesAddSelection, this.props.selectedWorkspace.id)
    );
    this.closeDevicesAddDialog();
  }

  async handleAddDevicesBySerial(workspaceId, serials) {
    await this.props.dispatch(addDevicesBySerial(workspaceId, serials));
    this.setState({ devicesAddSerialOpen: false });
  }

  handleRemoveFromStudy(studyId, deviceId) {
    if (studyId && deviceId) {
      this.props.dispatch(removeFromStudy({ studyId, deviceIds: [deviceId] }, true));
    }
    return;
  }

  linkDevicesToStudy({ studyId, deviceIds }) {
    studyId && deviceIds && this.props.dispatch(addToStudy({ studyId, deviceIds }, true));
  }

  render() {
    const {
      selection,
      showRetired,
      activeTab,
      mode,
      searchText,
      columnAnchorEl,
      selectingTargetWorkspace,
      targetWorkspaceId,
      devicesAddOpen,
      devicesAddSelection,
      devicesAddSerialOpen,
      studyChooseOpen,
      studyUnlinkOpen,
      studyLinkOpen,
    } = this.state;
    const {
      codespaces,
      models,
      devices,
      uniqueDevices,
      loading,
      classes,
      selectedStudy,
      workspaces,
      dispatch,
      selectedWorkspace,
      personalDevices,
      personalDevicesError,
      personalDevicesLoading,
      detectionsLoading,
      user,
    } = this.props;

    if (loading) {
      return (
        <div className={classes.loading}>
          <SpinnerInnovasea />
        </div>
      );
    }

    const tableCols = [
      {
        width: 70,
        label: "Serial",
        dataKey: "serial",
      },
      {
        width: 95,
        label: "Model",
        dataKey: "model",
        flexShrink: 1,
        flexGrow: 0,
      },
      {
        width: 140,
        label: "Stock Code",
        dataKey: "stockCode",
      },
      {
        width: 180,
        label: activeTab === "receivers" ? "Self-Transmitter ID(s)" : "Transmitter ID(s)",
        dataKey: "transmitters",
        renderFn: transmitters => transmitters.map(tx => tx.displayId).join(", "),
        flexGrow: 4,
      },
      {
        width: 100,
        label: "State",
        dataKey: "state",
        renderFn: state => toTitleCase(state),
      },
      {
        width: 110,
        label: "Ship Date",
        dataKey: "shipDate",
        renderFn: shipDate => shipDate && shipDate.split("T")[0],
      },
      {
        width: 60,
        label: "Retired",
        dataKey: "retired",
        searchTextFn: () => "",
        renderFn: retired => retired && <Check />,
        sortFn: a => (a ? 1 : -1),
        flexShrink: 1,
        flexGrow: 0,
      },
      {
        width: 60,
        label: "Source",
        dataKey: "source",
        searchTextFn: () => "",
        renderFn: source =>
          source === "MANUAL" ? (
            <ManualDeviceIcon titleAccess="User" />
          ) : source === "MOBILE" ? (
            <MobileAddIcon titleAccess="Mobile" />
          ) : (
            <div style={{ textAlign: "center", width: "24px" }}>
              <img alt="Fathom" src="/img/fathom-pin.png" height="24px" title="Fathom" />
            </div>
          ),
        flexShrink: 1,
        flexGrow: 0,
      },
      ...(!selectedStudy
        ? [
            {
              width: 150,
              label: "In Studies",
              dataKey: "inStudies",
              searchTextFn: studyNames,
              renderFn: (studies, rowData) => (
                <StudyLinkChips
                  studies={studies}
                  removeFromStudy={this.handleRemoveFromStudy}
                  objectId={rowData.id}
                />
              ),
              noEllipses: true,
            },
          ]
        : []),
      {
        width: 60,
        label: "Log Files",
        dataKey: "rxLogFiles",
        searchTextFn: () => "",
        renderFn: rxLogFiles => (rxLogFiles ? rxLogFiles.length : ""),
        sortFn: (a, b) => (a?.length || 0) - (b?.length || 0),
        flexShrink: 1,
        flexGrow: 0,
      },
      {
        width: 60,
        label: "Detections",
        dataKey: "detectCount",
        sortFn: (a, b) => (a > b ? 1 : -1),
        searchTextFn: () => "",
        renderFn: (count, device) =>
          device.deviceClasses.includes("RECEIVER") ? (
            !detectionsLoading ? (
              count?.toLocaleString()
            ) : (
              <CircularProgress size={14} />
            )
          ) : (
            ""
          ),
        numeric: true,
      },
    ];

    const selectedCols = tableCols.filter(col => this.state.selectedColKeys.includes(col.dataKey));

    const conflictKey = selectedStudy ? "studyConflicts" : "conflicts";
    const anyConflicts = devices.some(d => d[conflictKey].length > 0);
    if (anyConflicts) {
      selectedCols.push({
        width: 50,
        label: "",
        dataKey: conflictKey,
        searchTextFn: () => "",
        renderFn: conflicts =>
          conflicts.length > 0 && <WarningIcon tooltip={this.getConflictMessage(conflicts)} />,
        noEllipses: true,
        disableSort: true,
        flexShrink: 1,
      });
    }

    const devicesToDisplay = this.visibleDeviceList();

    const devicesSelected = [];
    const sysproSelection = [];
    const manualMobileSelection = [];

    selection.forEach(deviceId => {
      const device = devices.find(device => device.id === deviceId);
      if (device) {
        devicesSelected.push(device);

        if (device.source === "SYSPRO") {
          sysproSelection.push(device);
        } else {
          manualMobileSelection.push(device);
        }
      }
    });

    const noneSelected = selection.length === 0;
    const deleteDisabled =
      devicesSelected.length === 0 ||
      (selectedWorkspace.isPersonal && devicesSelected.length === sysproSelection.length);
    const retireDisabled = !devicesSelected.some(d => d.retired === false);
    const unretireDisabled = !devicesSelected.some(d => d.retired === true);

    const sharedWorkspaces = workspaces.filter(w => !w.isPersonal);

    const devicesToDelete = selectedWorkspace.isPersonal ? manualMobileSelection : devicesSelected;
    const shareDevicesVisble = selectedWorkspace.isPersonal && workspaces.length > 1;

    return (
      <>
        <ResizableSplitPanel
          direction="horizontal"
          firstMin={"60%"}
          secondMin={"28%"}
          firstInit={"70%"}
          firstContent={
            <div className={classes.main}>
              <MenuBar
                contentLeft={
                  <IconMenuHoriz
                    items={[
                      {
                        hoverText: "Add a device to this workspace",
                        icon: <IconAdd />,
                        onClick: this.openAddDevicesDialog,
                        labelAbove: "Add",
                      },
                      {
                        hoverText: deleteDisabled ? "Select devices to delete" : "Delete devices",
                        icon: <IconDelete />,
                        onClick: this.openDeleteDeviceDialog,
                        disabled: deleteDisabled,
                        labelAbove: "Delete",
                      },
                      { divider: true },
                      {
                        // (only shown outside of study context) for selecting a study to link to the currently selected devices
                        visible: !selectedStudy,
                        labelAbove: "Link",
                        icon: <IconLink />,
                        onClick: () => this.setState({ studyChooseOpen: true }),
                        disabled: selection.length === 0,
                        hoverText:
                          selection.length === 0
                            ? "Please select devices to link to a study"
                            : "Link the selected devices to a study",
                      },
                      {
                        // (only shown within a study context) for selecting devices to link to the currently selected study
                        visible: Boolean(selectedStudy),
                        labelAbove: "Link",
                        icon: <IconLink />,
                        onClick: () => this.setState({ studyLinkOpen: true }),
                        hoverText: "Link devices to this study from the workspace",
                      },
                      {
                        // (only shown within a study context) for unlinking currently selected devices from the currently selected study
                        visible: Boolean(selectedStudy),
                        labelAbove: "Unlink",
                        icon: <IconLinkOff />,
                        onClick: () => this.setState({ studyUnlinkOpen: true }),
                        disabled: selection.length === 0,
                        hoverText:
                          selection.length === 0
                            ? "Please select devices to unlink from this study"
                            : "Unlink the currently selected devices from this study",
                      },
                      { divider: true },
                      {
                        hoverText: noneSelected
                          ? "Select devices to add a rebattery event"
                          : "Add a rebattery event to devices",
                        icon: <IconBattery />,
                        onClick: this.openRebatteryDialog,
                        disabled: noneSelected,
                        labelAbove: "Rebattery",
                      },
                      { divider: true },
                      {
                        hoverText: retireDisabled ? "Retire devices" : "Select devices to retire",
                        icon: <IconPowerOff />,
                        onClick: e => this.retireDevices(e, selection),
                        disabled: retireDisabled,
                        labelAbove: "Retire",
                      },
                      {
                        hoverText: unretireDisabled
                          ? "Select retired devices to unretire"
                          : "Return devices from retirement",
                        icon: <IconFlashOn />,
                        onClick: e => this.unretireDevices(e, selection),
                        visible: showRetired,
                        disabled: unretireDisabled,
                        labelAbove: "Unretire",
                      },
                      { visible: shareDevicesVisble, divider: true },
                      {
                        visible: shareDevicesVisble,
                        labelAbove: "Copy to Workspace",
                        icon: <IconCopyToWorkspace />,
                        onClick: () => this.setState({ selectingTargetWorkspace: true }),
                        disabled: noneSelected || sharedWorkspaces.length === 0,
                        hoverText: "Copy selected devices to a shared workspace",
                      },
                    ]}
                  />
                }
                contentRight={
                  <>
                    <IconMenuHoriz
                      items={[
                        {
                          hoverText: this.state.showRetired
                            ? "Hide retired devices"
                            : "Show retired devices",
                          isSwitch: true,
                          switchChecked: showRetired,
                          onClick: () => {
                            showRetired === this.state.selectedColKeys.includes("retired") &&
                              this.columnSelectionHandler("retired");
                            this.setState({ showRetired: !showRetired });
                          },
                          labelAbove: "Show Retired",
                        },
                        { divider: true },
                      ]}
                    />
                    <FlexCol fullHeight>
                      <Typography variant="caption">Device Type</Typography>
                      <RadioGroup
                        row
                        name="deviceClasses"
                        value={activeTab}
                        onChange={e => this.setState({ activeTab: e.target.value })}
                      >
                        <FormControlLabel
                          value="all"
                          control={<Radio size="small" />}
                          label="All"
                        />
                        <FormControlLabel
                          value="receivers"
                          control={<Radio size="small" />}
                          label="Receivers"
                        />
                        <FormControlLabel
                          value="tags"
                          control={<Radio size="small" />}
                          label="Tags"
                        />
                      </RadioGroup>
                    </FlexCol>
                    <TableSearchBar
                      searchText={searchText}
                      handleSearch={searchText => {
                        this.setState({ searchText });
                      }}
                    />
                    <Tooltip title="Change visible columns">
                      <IconButton
                        size="small"
                        aria-label="Change visible columns"
                        onClick={e => this.setState({ columnAnchorEl: e.currentTarget })}
                      >
                        <ViewColumnIcon />
                      </IconButton>
                    </Tooltip>
                  </>
                }
              />

              <Popover
                open={Boolean(columnAnchorEl)}
                anchorEl={columnAnchorEl}
                onClose={() => this.setState({ columnAnchorEl: null })}
                anchorOrigin={{
                  vertical: "bottom",
                  horizontal: "left",
                }}
              >
                <Paper
                  style={{
                    maxHeight: "calc(100vh - 100px)",
                    overflowY: "auto",
                  }}
                >
                  <MenuList dense={true}>
                    {tableCols.map(c => (
                      <MenuItem
                        value={c.label}
                        key={c.dataKey}
                        onClick={() => {
                          this.columnSelectionHandler(c.dataKey);
                        }}
                        dense={true}
                      >
                        <Checkbox checked={this.state.selectedColKeys.includes(c.dataKey)} />
                        <Typography> {c.label}</Typography>
                      </MenuItem>
                    ))}
                  </MenuList>
                </Paper>
              </Popover>

              {selectedStudy && devicesToDisplay.length <= 0 ? (
                <div style={{ padding: 20 }}>
                  <StudyEmptyMessage
                    typeName="devices"
                    studyName={selectedStudy.name}
                    onLinkClicked={() => this.setState({ studyLinkOpen: true })}
                    onNewClicked={this.openAddDevicesDialog}
                  />
                </div>
              ) : (
                <div className={classes.deviceTableContainer}>
                  <WindowedTable
                    rows={devicesToDisplay}
                    loading={loading}
                    selection={selection}
                    onSelect={selection => {
                      this.setState({ selection });
                    }}
                    searchText={searchText}
                    activeTab={activeTab}
                    columns={selectedCols}
                    selectable={true}
                    selectOnRowClick
                    rowIdKey="id"
                  />
                </div>
              )}
            </div>
          }
          secondContent={
            <DeviceDetailsPanel
              editDevice={this.openEditDeviceDialog}
              devicesSelected={devicesSelected}
              deleteEvent={(eventId, deviceId) => dispatch(deleteDeviceEvents([eventId], deviceId))}
            />
          }
        />

        <DeviceDialog
          toggle={this.closeDeviceDialog}
          mode={mode}
          models={models}
          codespaces={codespaces}
          handleDeviceSubmit={this.handleDeviceSubmit}
          device={devicesSelected[0]}
          uniqueDevices={uniqueDevices}
          selectedStudy={selectedStudy}
          copyDevicesToWorkspace={this.copyDevicesToWorkspace}
          linkDevicesToStudy={this.linkDevicesToStudy}
        />

        <RebatteryDialog
          toggle={this.closeDeviceDialog}
          mode={mode}
          handleDeviceRebattery={this.handleDeviceRebattery}
          devices={devicesSelected}
        />

        <DialogWrapper
          open={this.state.mode === "delete"}
          title="Delete Devices"
          cancelAction={this.closeDeviceDialog}
          okAction={() => this.handleDeviceDelete(devicesToDelete)}
          buttons={({ cancelAction, okAction }) => (
            <DeleteDialogButtons
              cancelAction={cancelAction}
              okAction={okAction}
              isStudy={Boolean(selectedStudy)}
              unlinkAction={this.actionUnlink}
            />
          )}
        >
          {
            devicesToDelete.length > 0 && 
              <Typography paragraph>
              Are you sure you want to delete
                {
                  devicesToDelete.length > 1
                    ? ` ${devicesToDelete.length} devices`
                    : ` device ${devicesToDelete[0].model + " " + devicesToDelete[0].serial}`
                } 
              ?
            </Typography> //prettier-ignore
          }
          {Boolean(selectedStudy) && <DeleteWarning type="device" />}
          {selectedWorkspace.isPersonal && sysproSelection.length > 0 && (
            <>
              <Typography paragraph>
                You cannot delete devices automatically populated by Innovasea from your personal
                workspace. To hide these devices, you can retire them instead.
              </Typography>
              <ExpansionPanel className={classes.panel}>
                <ExpansionPanelSummary style={{ padding: 0 }} expandIcon={<IconExpandMore />}>
                  <div>
                    {sysproSelection.length} device
                    {sysproSelection.length > 1 ? "s " : " "}
                    cannot be deleted
                  </div>
                </ExpansionPanelSummary>
                <ExpansionPanelDetails style={{ padding: 0, display: "inline" }}>
                  {sysproSelection.map(device => (
                    <div key={device.serial}>{device.model + " " + device.serial}</div>
                  ))}
                </ExpansionPanelDetails>
              </ExpansionPanel>
            </>
          )}
        </DialogWrapper>

        <DialogWrapper
          title="Copy Devices to Workspace"
          cancelAction={() => this.setState({ selectingTargetWorkspace: false })}
          okAction={() => {
            dispatch(copyDevicesToWorkspace(selection, targetWorkspaceId));
            this.setState({ selectingTargetWorkspace: false });
          }}
          okButtonContent="Copy Devices"
          open={selectingTargetWorkspace}
        >
          <TextField
            select
            value={targetWorkspaceId}
            onChange={e => this.setState({ targetWorkspaceId: e.target.value })}
            label="Workspace to Copy Into"
            fullWidth
          >
            {sharedWorkspaces.map(w => (
              <MenuItem key={w.id} value={w.id}>
                {w.name}
              </MenuItem>
            ))}
          </TextField>
          {deviceCopyMessage}
        </DialogWrapper>

        <DevicesAddDialog
          open={devicesAddOpen}
          handleClose={this.closeDevicesAddDialog}
          selectedWorkspace={selectedWorkspace}
          personalDevices={personalDevices}
          personalDevicesError={personalDevicesError}
          personalDevicesLoading={personalDevicesLoading}
          selection={devicesAddSelection}
          setSelection={this.setDevicesAddSelection}
          copyDevicesToWorkspace={this.copyDevicesToWorkspace}
          switchToManual={this.openAddManualDeviceDialog}
          switchToSerial={!user.isExternal && this.openDevicesAddSerialDialog}
        />

        {devicesAddSerialOpen && (
          <DevicesAddSerial
            open={true}
            handleClose={() => this.setState({ devicesAddSerialOpen: false })}
            addDevicesBySerial={(workspaceId, serials) => {
              this.handleAddDevicesBySerial(workspaceId, serials);
            }}
          />
        )}

        {studyChooseOpen && (
          <StudyChooseDialog
            open={true}
            handleClose={() => this.setState({ studyChooseOpen: false })}
            objectType="deviceIds"
            objectTypeLabel="Device"
            objectIds={selection}
          />
        )}

        {studyLinkOpen && (
          <StudyLinkDialog
            open={true}
            handleClose={() => this.setState({ studyLinkOpen: false })}
            objectType="deviceIds"
            objectTypeLabel="Device"
            selectedStudyId={selectedStudy?.id}
          />
        )}

        {studyUnlinkOpen && (
          <StudyUnlinkDialog
            open={true}
            handleClose={() => this.setState({ studyUnlinkOpen: false })}
            objectType="deviceIds"
            objectTypeLabel="Device"
            objectIds={selection}
            selectedStudyId={selectedStudy?.id}
          />
        )}
      </>
    );
  }
}

function formatDevice(device) {
  const isSensorTag = device.transmitters.some(tx => tx.isSensor);
  return {
    id: device.id || undefined,
    deviceClasses: [device.deviceClass],
    model: device.model,
    serial: device.serial,
    source: "MANUAL",
    sensorsSupport0ADC: isSensorTag ? device.sensorsSupport0ADC : null,
    transmitters: device.transmitters.map(tx => {
      const transmissionType = tx.transmissionType;
      let codedTransmitter, htiTransmitter;

      if (transmissionType == "ACOUSTIC_HTI") {
        htiTransmitter = {
          transmissionType: tx.transmissionType,
          htiPeriodMs: parseFloat(tx.htiPeriodMs),
          htiSubcode: parseInt(tx.htiSubcode),
          htiPulseWidth: tx.htiPulseWidth,
          htiModulation: parseInt(tx.htiModulation),
        };
      } else {
        codedTransmitter = {
          transmitId: parseInt(tx.transmitId),
          codespace: {
            displayString: tx.codespace,
          },
        };

        if (tx.isSensor) {
          codedTransmitter.sensorDef = {
            dimension: tx.dimension,
            slope: parseFloat(tx.slope),
            intercept: parseFloat(tx.intercept),
          };
        }
      }

      return { codedTransmitter, htiTransmitter };
    }),
  };
}

Devices.propTypes = {
  devices: PropTypes.array,
  codespaces: PropTypes.array,
  models: PropTypes.object,
  loading: PropTypes.bool,
  error: PropTypes.array,
  selectedStudy: PropTypes.object,
  workspaces: PropTypes.array,
  selectedWorkspace: PropTypes.object,
  user: PropTypes.object,
};

const mapStateToProps = ({ devices, study, workspaces, detection, user }) => {
  const studySelectedId = study.selectedId;
  const selectedStudy =
    studySelectedId && study.studies.find(study => study.id === studySelectedId);
  const { detectionSummary } = detection.summary;
  const devicesWithDetections = detectionSummary?.receiverSummary?.filter(d =>
    Boolean(d.receiver.deviceId)
  );

  // Need to know the devices in the workspace to prevent duplication:
  /** Set of unique serials and full transmit ids in the study, current workspace, and personal
   * workspace. The distinction is used for clear communication to the user. */
  const uniqueDevices = {
    study: {
      serials: {},
      fullIds: {},
    },
    workspace: {
      serials: {},
      fullIds: {},
    },
    personal: {
      serials: {},
      fullIds: {},
    },
  };
  const deviceIdMap = {};

  devices?.devices?.forEach(device => {
    deviceIdMap[device.id] = device;
    if (device.serial) {
      uniqueDevices.workspace.serials[device.serial] = device;
    }
    if (device.transmitters) {
      device.transmitters.forEach(tx => (uniqueDevices.workspace.fullIds[tx.displayId] = device));
    }
  });

  devices?.personalDevices?.forEach(device => {
    if (device.serial) {
      uniqueDevices.personal.serials[device.serial] = device;
    }
    if (device.transmitters) {
      device.transmitters.forEach(tx => (uniqueDevices.personal.fullIds[tx.displayId] = device));
    }
  });

  if (selectedStudy) {
    selectedStudy.devices?.forEach(({ id }) => {
      const device = deviceIdMap[id];

      if (device?.serial) {
        uniqueDevices.study.serials[device.serial] = device;
      }
      if (device?.transmitters) {
        device.transmitters.forEach(tx => (uniqueDevices.study.fullIds[tx.displayId] = device));
      }
    });
  }

  let deviceList = studyVisibleObjects({
    studies: study.studies,
    selectedStudy,
    objects: devices.devices,
    objectKey: "devices",
  });

  if (devicesWithDetections) {
    deviceList = deviceList.map(d => {
      const idCounts = devicesWithDetections.find(
        dwd => dwd.receiver.serial === d.serial
      )?.idCounts;
      const detectCount = idCounts ? idCounts.reduce((sum, c) => sum + c.count, 0) : 0;
      return {
        ...d,
        idCounts,
        detectCount,
      };
    });
  }
  return {
    devices: deviceList,
    uniqueDevices,
    codespaces: devices.codespaces,
    models: devices.models,
    loading: devices.loading,
    selectedStudy,
    workspaces: workspaces.workspaces,
    selectedWorkspace: workspaces.selectedWorkspace,
    personalDevices: devices.personalDevices,
    personalDevicesError: devices.personalDevicesError,
    personalDevicesLoading: devices.personalDevicesLoading,
    detectionsLoading: detection.loading,
    user: user.user,
  };
};

export default compose(connect(mapStateToProps), withStyles(styles))(Devices);
